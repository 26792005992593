<template>
  <v-app id="admin-container">
    <Header />
    <LeftBar />
    <v-main>
      <Notification text="Updated Successfully!" status="success" />
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import Notification from '@/components/common/Notification.vue';
import { mapActions } from 'vuex';
import endpoints from "@/config/endpoints";
export default {
  components: {
    Notification,
    LeftBar: () => import("@/components/admin/LeftBar"),
    Header: () => import("@/components/admin/Header"),
  },
  methods: {    
    ...mapActions('app', ['getSettingCall']),
    handleToggleDrawer(smt) {
      this.drawer = smt;
    },
  },
  mounted() {
    const favicon = document.getElementById('favicon');
    this.getSettingCall({
      st_category_id: 101,
      st_category_type: 100
    }).then(res => {
      favicon.href = `${endpoints.FILE_DOWNLOAD}/${JSON.parse(res).file_id}`
    });
  }
};
</script>
<style>
</style>
